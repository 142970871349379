/* Home page css */
#home_anime_1 {
    font-size: clamp(30px, 5vw, 70px);
}
#home_anime_2{
    font-size: clamp(10px, 5vw, 30px);
}

.right_left_fade_in {
    animation: right_left_fade_in 5s infinite;
    animation-iteration-count: 1;
}
.left_right_fade_in {
    animation: left_right_fade_in 5s infinite;
    animation-iteration-count: 1;
}

@keyframes right_left_fade_in {
    0%{
        transform: translateX(400px);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes left_right_fade_in {
    0%{
        transform: translateX(-100px);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}



/* Message Page css */
#msg_1 {
    font-size: clamp(30px, 5vw, 70px);
}
#msg_2{
    font-size: clamp(10px, 5vw, 30px);
}

.popUp {
    animation: popUp 2s infinite;
    animation-iteration-count: 1;
    opacity: 1 !important;
}
.op{
    opacity: 0;
}
.popUp_2 {
    animation: popUp_2 2s infinite;
    animation-iteration-count: 1;
    opacity: 1;
}


@keyframes popUp {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        transform: scale(1.08);
        /* opacity: 0.8; */
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes popUp_2 {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        transform: scale(0.5);
        opacity: 0;
    }
    75%{
        transform: scale(1.08);
        opacity: 0.5;
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.msg_popUp.op {
    animation: msg_popUp 0.8s linear infinite;
    animation-iteration-count: 1;
    opacity: 1;
}
@keyframes msg_popUp {
    0%{
        transform: translateY(10px) scale(0.5);
        opacity: 0.5;
    }
    50%{
        transform: translateY(5px) scale(1.05);
        opacity: 0.8;
    }
    100%{
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

/* Search page */
.search_text_1 {
    font-size: clamp(30px, 5vw, 70px);
}
.search_text_2{
    font-size: clamp(5px, 5vw, 25px);
}

.text_visible {
    opacity: 0;
}
.text_visible.typewriter-text {
    opacity: 1;
    overflow: hidden; /* Ensures the text is not visible outside the container */
    border-right: .15em solid rgb(0, 0, 0); /* Cursor style */
    white-space: nowrap; /* Keeps text on one line */
    /* margin: 0 auto; Gives that typewriter effect */
    /* letter-spacing: .15em; Adjust as needed */
    animation:
        typing 3.5s steps(30, end),
        blink-caret .75s step-end infinite; /* Adjust timing and steps as needed */
}


.left_right_search.op {
    animation: left_right_sp 2s linear infinite;
    animation-iteration-count: 1;
    opacity: 1;
}
.right_left_search.op {
    animation: right_left_sp 2s linear infinite;
    animation-iteration-count: 1;
    opacity: 1;
}

@keyframes left_right_sp {
    0%{
        transform: translateX(-60px);
        opacity: 0;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes right_left_sp {
    0%{
        transform: translateX(60px);
        opacity: 0;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  

/* Blinking cursor animation keyframes */
@keyframes blink-caret {
from, to { border-color: transparent }
50% { border-color: rgb(5, 5, 5); }
}

/* Takes Page */
.takes_text_1{
    font-size: clamp(30px, 5vw, 70px);
}
.takes_text_2{
    font-size: clamp(20px, 5vw, 30px);
}

.left_right > p.text_visible {
    opacity: 1;
    animation: left_right 2s infinite;
    animation-iteration-count: 1;
}
.right_left > p.text_visible {
    opacity: 1;
    animation: right_left 2s infinite;
    animation-iteration-count: 1;
}
.translate_top{
    transform: translateY(-10px);

}
.fall_anime > p.text_visible {
    opacity: 1;
    animation: fall_anime 3s infinite;
    animation-iteration-count: 1;
}

@keyframes left_right {
    0%{
        transform: translateX(-250px);
    }
    100%{
        transform: translateX(0px);
    }
}
@keyframes right_left {
    0%{
        transform: translateX(90px);
    }
    100%{
        transform: translateX(0px);
    }
}
@keyframes fall_anime {
    0%{
        transform: translateY(-100px);
    }
    30%{
        transform: translateY(-100px);
    }
    50% {
        transform: translateY(0px);
    }
    60%{
        transform: translateY(-20px);
    }
    70%{
        transform: translateY(0px);
    }
    80%{
        transform: translateY(-10px);
    }
    90%{
        transform: translateY(0px);
    }
    95%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateX(0px);
    }
}

/* Tales Anime */
.tales_text_1{
    font-size: clamp(30px, 5vw, 70px);
}
.tales_text_2{
    font-size: clamp(20px, 5vw, 30px);
}


.tale_pop.op {
    opacity: 1;
    animation: popUp 1s infinite;
    animation-iteration-count: 1;
}
.tale_lr_anime.op {
    opacity: 1;
    animation: left_right 5s infinite;
    animation-iteration-count: 1;
}