/* .nav-mid > ul > li:hover > div {
    background-color: #FF9901;
    transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out; 
    width: 100%;
}
.nav-mid > ul > li > div{
    width: 0px;
}
.nav-mid > ul > li {
    cursor: pointer;
} */

.nav-mid a {
    position: relative;
    padding-bottom: 5px;
    cursor: pointer;
  }
  
  .nav-mid a:hover + div,
  .nav-mid .underline {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #FF9901; /* change color as needed */
  }
  
  .nav-mid a:hover + div {
    transition: width 0.3s ease;
  }
  
  .nav-mid .underline {
    width: 100%;
    transition: width 0.3s ease, background-color 0.3s ease;
  }
  
  .nav-mid div {
    width: 0;
    height: 1px;
    background-color: transparent;
    transition: width 0.3s ease;
  }

  .nav-mid li {
    position: relative;
  }
  .nav-mid .underline{
    position: absolute;
    bottom: 0;
  }