.custom-dropdown{
    position: relative !important;
    transform: none !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.custom-dropdown > ul > li >button{
    color: black !important;
    font-size: 15px;
    width: 80%  !important;
}
