.main-footer{
    background: linear-gradient(to bottom right, rgba(20, 40, 58, 1), rgba(11, 28, 28, 1));
}

input, textarea{
    font-size: 14px !important;
}

textarea {
    resize: none; /* Disable the manual resize */
    overflow: hidden; /* Hide the scrollbar */
  }
  