.scrollbar-w-none {
    scrollbar-width: none;
}
.twig_carousal > div{
    transition: transform 1s ease-in-out
}
.main_pg_scroll {
    /* height: 90vh; */
    /* background-color: red; */
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}
.main_pg_scroll > div {
    scroll-snap-align: center;
}


.scroll_pg {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    position: relative;
}


.fade-in {
    opacity: 0;
    transform: translateY(5px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    z-index: 0;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    z-index: 0;
  }


.slider_0 {
    transform: translateX(0);
    transition: all;
}
.slider_1 {
    transform: translateX(-100%);
    transition: all;
    
}
.slider_3 {
    transform: translateX(-300%);
    transition: all;

}

.main_page_text_1 {
    font-size: clamp(20px, 5vw, 50px);
}
.main_page_text_2 {
    font-size: clamp(10px, 2vw, 16px);
}
