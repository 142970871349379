/* .pg_1_height{
    height: calc(100% - 10px);
} */

.text_1{
    animation: moveText1 10s infinite;
}
.text_2{
    animation: moveText2 10s infinite;
}
.text_3{
    animation: moveText3 10s infinite;
}

@keyframes moveText1 {
    0% {
      transform: translateY(0);
    }
    30% {
        transform: translateY(300px);
        color: #cbc6982f;
    }
    60% {
        transform: translate(200px, 150px);
        color: #18b08740;
    }
    100% {
        transform: translateY(0px);
    }
  }
@keyframes moveText2 {
    0% {
        transform: translateY(0);
      }
      30% {
        transform: translate(-200px, -150px);
        color: #01538B;
    }
    60% {
        transform: translate(-200px, 150px);
        color: #cbc6982f;
      }
      100% {
          transform: translateY(0);
      }
  }
@keyframes moveText3 {
    0% {
        transform: translateY(0px);
    }
    30% {
        transform: translate(200px, -150px);
        color: #18b08740;
    }
    60% {
        transform: translate(0px, -300px);
        color: #01538B;
    }
    100% {
        transform: translateY(0px);
    }
  }

  

@media (max-width: 640px) { 
      .text_1{
          animation: moveText1_mob 10s infinite;
      }
      .text_2{
          animation: moveText2_mob 10s infinite;
      }
      .text_3{
          animation: moveText3_mob 10s infinite;
      }
      
      @keyframes moveText1_mob {
          0% {
            transform: translateY(0);
          }
          30% {
              transform: translateY(150px);
              color: #cbc6982f;
          }
          60% {
              transform: translate(150px, 75px);
              color: #18b08740;
          }
          100% {
              transform: translateY(0px);
          }
        }
      @keyframes moveText2_mob {
          0% {
              transform: translateY(0);
            }
            30% {
              transform: translate(-180px, -75px);
              color: #01538B;
          }
          60% {
              transform: translate(-180px, 75px);
              color: #cbc6982f;
            }
            100% {
                transform: translateY(0);
            }
        }
      @keyframes moveText3_mob {
          0% {
              transform: translateY(0px);
          }
          30% {
              transform: translate(180px, -75px);
              color: #18b08740;
          }
          60% {
              transform: translate(0px, -150px);
              color: #01538B;
          }
          100% {
              transform: translateY(0px);
          }
        }
}