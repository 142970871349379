.policy-pages ul ul{
    list-style: disc;
    padding-left: 1.25rem;
}

.policy-pages h2, .policy-pages h3{
    font-size: 24px;
    font-weight: 500;
    padding: 5px 0;
}

.policy-pages a{
    text-decoration: underline;
    color: green;
}