@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

html, body {
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 30px 10px; */
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
}


input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 170%;
  height: 100%;
  /* margin-bottom: 20px; */
}

.card > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  object-fit: cover;
}
/* .card > video {
  width: 50%;
  height: 50%;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  object-fit: contain;
} */

#item-1:checked ~ .cards #song-3,
#item-2:checked ~ .cards #song-1,
#item-3:checked ~ .cards #song-2 {
  transform: translatex(-30%) scale(.8);
  filter: blur(5px);
  opacity: .9;
  z-index: 0;
}

#item-1:checked ~ .cards #song-2,
#item-2:checked ~ .cards #song-3,
#item-3:checked ~ .cards #song-1 {
  transform: translatex(30%) scale(.8);
  filter: blur(5px);
  opacity: .9;
  z-index: 0;
}

#item-1:checked ~ .cards #song-1,
#item-2:checked ~ .cards #song-2,
#item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}

#item-1:checked ~ .cards #song-1 img,
#item-2:checked ~ .cards #song-2 img,
#item-3:checked ~ .cards #song-3 img {
  box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
}

/* .player {
  background-color: #fff;
  border-radius: 8px;
  min-width: 320px;
  padding: 16px 10px;
}

.upper-part {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 36px;
  overflow: hidden;
}

.play-icon {
  margin-right: 10px;
}

.song-info {
  width: calc(100% - 32px);
  display: block;
}

.song-info .title {
  color: #403d40;
  font-size: 14px;
  line-height: 24px;
}

.sub-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subtitle, .time {
  font-size: 12px;
  line-height: 16px;
  color: #c6c5c6;
}

.time {
  font-size: 12px;
  line-height: 16px;
  color: #a5a5a5;
  font-weight: 500;
  margin-left: auto;
}

.progress-bar {
  height: 3px;
  width: 100%;
  background-color: #e9efff;
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  display: block;
  position: relative;
  width: 60%;
  height: 100%;
  background-color: #2992dc;
  border-radius: 6px;
}

.info-area {
  width: 100%;
  position: absolute;
  top: 0;
  left: 30px;
  transition: transform .4s ease-in;
} 
#item-1:checked ~ .player #test {
  transform: translateY(0);
}

#item-2:checked ~ .player #test {
  transform: translateY(-40px);
}

#item-3:checked ~ .player #test {
  transform: translateY(-80px);
}
*/


/* Takes part */
.video_container {
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* padding: 30px 10px; */
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
}
.video_cards {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.video_card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
}

.video_card > video {
  width: 100%;
  height: 100%;
  /* border-top-left-radius: 15px; */
  /* border-bottom-right-radius: 15px; */
  border-radius: 30px;
  object-fit: cover;
}

#video_item-1:checked ~ .video_cards #video-3,
#video_item-2:checked ~ .video_cards #video-1,
#video_item-3:checked ~ .video_cards #video-2 {
  transform: translatex(40%) translateY(15%) scale(.6);
  filter: blur(10px);
  opacity: 1;
  z-index: 0;
}

#video_item-1:checked ~ .video_cards #video-2,
#video_item-2:checked ~ .video_cards #video-3,
#video_item-3:checked ~ .video_cards #video-1 {
  transform: translatex(10%) translateY(8%) scale(.8);
  filter: blur(10px);
  opacity: 1;
  z-index: 1;
}

#video_item-1:checked ~ .video_cards #video-1,
#video_item-2:checked ~ .video_cards #video-2,
#video_item-3:checked ~ .video_cards #video-3 {
  transform: translatex(-20%) scale(1);
  opacity: 1;
  z-index: 2;
}

#video_item-1:checked ~ .video_cards #video-1 video,
#video_item-2:checked ~ .video_cards #video-2 video,
#video_item-3:checked ~ .video_cards #video-3 video {
  box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
}

#video_item-1:checked ~ .player #test {
  transform: translateY(0);
}

#video_item-2:checked ~ .player #test {
  transform: translateY(-40px);
}

#video_item-3:checked ~ .player #test {
  transform: translateY(-80px);
}


/* Tales Slider */

.tale_container {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
}
.tale_cards {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.tale_card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
}

.tale_card > img {
  width: 80%;
  height: 100%;
  /* border-top-left-radius: 15px; */
  /* border-bottom-right-radius: 15px; */
  border-radius: 30px;
  object-fit: cover;
}

#tale_item-1:checked ~ .tale_cards #tale-3,
#tale_item-2:checked ~ .tale_cards #tale-1,
#tale_item-3:checked ~ .tale_cards #tale-2 {
  transform: translateX(-27%) translateY(7%) scale(.8) rotate(-15deg);
  filter: blur(10px);
  opacity: 1;
  z-index: 0;
}

#tale_item-1:checked ~ .tale_cards #tale-2,
#tale_item-2:checked ~ .tale_cards #tale-3,
#tale_item-3:checked ~ .tale_cards #tale-1 {
  transform: translateX(45%)  translateY(10%) scale(.8) rotate(15deg);
  filter: blur(10px);
  opacity: 1;
  z-index: 1;
}

#tale_item-1:checked ~ .tale_cards #tale-1,
#tale_item-2:checked ~ .tale_cards #tale-2,
#tale_item-3:checked ~ .tale_cards #tale-3 {
  transform: translateX(10%) scale(1);
  opacity: 1;
  z-index: 2;
}

#tale_item-1:checked ~ .tale_cards #tale-1 img,
#tale_item-2:checked ~ .tale_cards #tale-2 img,
#tale_item-3:checked ~ .tale_cards #tale-3 img {
  box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
}

#tale_item-1:checked ~ .player #test {
  transform: translateY(0);
}

#tale_item-2:checked ~ .player #test {
  transform: translateY(-40px);
}

#tale_item-3:checked ~ .player #test {
  transform: translateY(-80px);
}

@media (max-width: 640px) { 

  .video_cards {
    margin-bottom: 0px;
    margin-top: 50px;
  }

  .video_card {
    width: clamp( 180px, 50vw,  100%);
    height: 80%;
  }

  .tale_card {
    width: 150%;
  }
  
}